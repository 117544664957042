import MercadoPagoService from '../../services/MercadoPagoService';
import { useState } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import Cards from 'react-credit-cards-2';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { CurrencyUtils } from '../../Utils/CurrencyUtils';
import {Spinner} from  "reactstrap";
import * as signalR from "@microsoft/signalr";
import StringUtils from '../../Utils/StringUtils';
import UsuarioService from '../../services/UsuarioService';
import 'react-credit-cards-2/es/styles-compiled.css';

const CadastroContrato = ({precisaRenovar, nomeContratante, senhaContratante, onCadastroContrato, onRefresh}) => {
  
  const [cvc, setCvc]                 = useState('')
  const [expiry, setExpiry]           = useState('')
  const [focus, setFocus]             = useState('')
  const [name, setName]               = useState('')
  const [number, setNumber]           = useState('')

  const [planoDescricao, setPlanoDescricao] = useState('Mensal')
  const [valorPlano, setValorPlano]   = useState(49.9)    
  const [valorAtualizadoPix, setValorPlanoAtualizadoPix]  = useState(49.9)  
  const [cupomSearch, setCupomSearch] = useState('')
  const [cupom, setCupom] = useState(0)  
  const [loadingAplicarCupom, setLoadingAplicarCupom] = useState(false)
  
  const [passo1, setPasso1]           = useState(true)
  const [passo2, setPasso2]           = useState(false)  
  const [passo3, setPasso3]           = useState(false)

  const [processandoPagamento, setProcessandoPagamento] = useState(false)

  //Estados para mostrar mensagens de erros nos campos
  const[exibeMensagemNumeroCartao, setExibeMensagemNumeroCartao] = useState(false)

  //PIX  
  const[qrCode, setQrCode] = useState('')
  const[qrCodeCopy, setQrCodeCopy] = useState('')
  const[cpf, setcpf] = useState('')  
  const[email, setEmail] = useState('')

  const usuarioService = new UsuarioService()
  
  let mercadoPagoService = new MercadoPagoService();
  const mp = useMercadopago.v2(mercadoPagoService.obterPublicKey(), {
    locale: 'pt-BR'
  });
            
    var cardform;    
    
    const criarformPagamentoCartao = (valor) => {
      cardform = mp.cardForm({
        amount: valor.toString(),
        autoMount: true,
        form: {
          id: "form-checkout",
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "0000 0000 0000 0000",
          },
          cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "",
          },
          cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Parcelas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Somente números",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emissor",
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error) return console.warn("Form Mounted handling error: ", error);
            console.log("Form mounted");
          },
          onPaymentMethodsReceived: error => {
            setExibeMensagemNumeroCartao(false)
           
            if (error) 
               setExibeMensagemNumeroCartao(true)

          },
          onSubmit: async event => {
            event.preventDefault();
                 
            if(!validarDadosOnSubmit(cardform)) return;
            
            const {                    
                paymentMethodId:paymentMethodId,
                issuerId:issuerId,
                cardholderEmail: cardholderEmail,
                amount: amount,                    
                token,
                installments: installments,
                identificationNumber:identificationNumber,
                identificationType:identificationType
            } = cardform.getCardFormData();            
                                   
             let bodyRequisicao = {
                nomeUsuario: nomeContratante,
                senhaUsuario: senhaContratante,
                token,
                issuerId,
                paymentMethodId,
                transactionAmount: Number(amount),
                installments: Number(installments),
                description: "LocucaoManager",
                cupom: cupomSearch,
                payer: {
                  email:cardholderEmail,
                  identification: {
                    type: identificationType,
                    number: identificationNumber,
                  },
                },
              };

             setProcessandoPagamento(true)              
             
             let response = await mercadoPagoService.efetuarPagamentoCartaoCreditoNaoLogado(bodyRequisicao);
             
             if(response.status !== 200)
                setProcessandoPagamento(false)

             onCadastroContrato(response)
          },        
        }
      }); 
    }

    const validarDadosOnSubmit = () => {

      const {cardholderEmail,identificationNumber} = cardform.getCardFormData();

      if(!cpfEhValido(String(identificationNumber)))
      {
        toast("O cpf informado é inválido", {
          type:"warning"
        });

        return false;
      }
       
      if(!emailEhValido(cardholderEmail))
      {
        toast("O e-mail informado esta no formato inválido", {
          type:"warning"
        });

        return false;
      }

       return true;
    }

    const handleInputFocus = (e) => {
      setFocus(e.target.name);
    }

    const emailEhValido = (email) => {
        let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
        return emailRegex.test(email)
    }

    const cpfEhValido = (cpf) => {
      if (typeof cpf !== 'string') return false
      cpf = cpf.replace(/[^\d]+/g, '')
      if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
      cpf = cpf.split('')
      const validator = cpf
          .filter((digit, index, array) => index >= array.length - 2 && digit)
          .map( el => +el )
      const toValidate = pop => cpf
          .filter((digit, index, array) => index < array.length - pop && digit)
          .map(el => +el)
      const rest = (count, pop) => (toValidate(pop)
          .reduce((soma, el, i) => soma + el * (count - i), 0) * 10) % 11 % 10
      return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
    }

    const retornaSomenteNumeros = (value) => {
       return String(value).replace(/[^0-9]/g, '')
    }

    const normalizeCardnumber = (value) => {
       
       let somenteNumeros = retornaSomenteNumeros(value)

       return somenteNumeros.replace(/\s/g,"").match(/.{1,4}/g)?.join(" ").substr(0,19) || ""
    }

    const aplicarCupom = async () => {
       
         if(StringUtils.isNullOrEmpty(cupomSearch))
         {
          toast("Informe um cupom", {
            type:"warning"
          });

          return
         }

         setLoadingAplicarCupom(true)

         let cupomBd = await usuarioService.obterCupomValido(nomeContratante, senhaContratante, cupomSearch)         

         if(cupomBd !== null)
         {            
            setCupom(cupomBd.porcentagemDesconto)
         }
         else
         {
            setCupom(0)
            setCupomSearch('')
         }

         setLoadingAplicarCupom(false)
    }
    
    const handleInputChange = (e) => {            
      //Seta o número do cartão
      let numeroCartao = document.getElementById('form-checkout__cardNumber').value
      setNumber(numeroCartao)

      //Seta a data de validade do cartão
      let valdiadeMes = document.getElementById('form-checkout__cardExpirationMonth').value  
      let valdiadeAno = document.getElementById('form-checkout__cardExpirationYear').value          
      setExpiry(valdiadeMes + '/' + valdiadeAno)
      
      //seta o nome do cartão         
      let nomeCartao = document.getElementById('form-checkout__cardholderName').value 
      setName(nomeCartao)

      //Seta o código de segurança
      let cvc = document.getElementById('form-checkout__securityCode').value 
      setCvc(cvc)
      
    }

    const onSelectPlano = (e) => {
        const {value} = e.target;
        setValorPlano(value) 

        if(value === '49.9')
           setPlanoDescricao("Mensal")
        if(value === '180')
           setPlanoDescricao("Semestral")
        if(value === '360')
           setPlanoDescricao("Anual")
    }

    const onChangeCupom = (e) => {
       const {value} = e.target;
       if(value === '')
       {
          setCupom(0)
       }

       setCupomSearch(value)
    }

    //Seleção de valores
    const IrAoPasso1 = () => {
        setValorPlano(49.9)
        setPlanoDescricao("Mensal")
        setQrCode('')
        setQrCodeCopy('')        
        setPasso1(true)
        setPasso2(false)  
        setPasso3(false)
    }

    //Cartão
    const Passo1ParaPasso2 = () => {

      if(cupomSearch !== '' && cupom === 0)
      {
        toast("Clique em aplicar cupom para validar antes de prosseguir", {
          type:"warning"
        });

        return false;
      }

      let valorAtual = valorPlano
      if(cupom > 0)
      {
        valorAtual = valorPlano - (valorPlano * cupom / 100)       
        setValorPlano(valorAtual)
      } 
        criarformPagamentoCartao(valorAtual.toString())
        setPasso1(false)
        setPasso2(true)  
        setPasso3(false)     
    }

    //Pix
    const Passo1ParaPasso3 = () => {   
      
      if(cupomSearch !== '' && cupom === 0)
      {
        toast("Clique em aplicar cupom para validar antes de prosseguir", {
          type:"warning"
        });

        return false;
      }

      let valorAtual = valorPlano
      if(cupom > 0)
      {
        valorAtual = valorPlano - (valorPlano * cupom / 100)   
        console.log('pix',valorAtual)    
        
      } 
      setValorPlanoAtualizadoPix(valorAtual)
      
      setPasso1(false)
      setPasso2(false)  
      setPasso3(true)     
    }

    const GerarQrCode = async() => {
      
      if(StringUtils.isNullOrEmpty(email) || StringUtils.isNullOrEmpty(cpf))
      {
        toast("Informe todos os campos", {
          type:"warning"
        });
        return;
      }

      let bodyRequisicao = {
        nomeUsuario: nomeContratante,
        senhaUsuario: senhaContratante,                
        paymentMethodId: "pix",
        transactionAmount: valorAtualizadoPix,
        description: "LocucaoManager",
        cupom: cupomSearch,
        payer: {
          email:email,
          FirstName: nomeContratante,
          LastName: "User",
          identification: {
            type: "CPF",
            number: cpf,
          },
        },
      };

      setProcessandoPagamento(true)              
             
      let response = await mercadoPagoService.cadastrarPagamentoPix(bodyRequisicao);
      
      if(response.status === 200)
      {
        var pagamentoPix = response.data;

        setQrCode(pagamentoPix.qr_codeBase64)
        setQrCodeCopy(pagamentoPix.codigoPix)        

        let pixHub = process.env.REACT_APP_PATH_SERVICES + "PixHub";

        const hubConnection = new signalR.HubConnectionBuilder()
                                         .withUrl(pixHub)   
                                         .withAutomaticReconnect()                                      
                                         .configureLogging(signalR.LogLevel.Information)  
                                         .build();
                                            
        await hubConnection.start();                                                     

        let idPagamento = pagamentoPix.idPagamento.toString();

        hubConnection.invoke("AdicionarGrupoIdPagamento", idPagamento);
                                                              
        hubConnection.on("ReceberMensagemRenovacao", jwt => {                     
                          mercadoPagoService.setarLocalStorageUsuarioPix(jwt);
                          onCadastroContrato(response);
                      });                      
      }

      setProcessandoPagamento(false)
    }

    const VoltarPaginaValores = () => {
      onRefresh();
    }
       
    return(
        <>
          { precisaRenovar 
           ?
           <form id="form-checkout">
           {      
              <>
              <div className={classNames({
                "d-none": passo2 === true || passo3 === true,
                "row": passo1 === true
              }
              )}>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-12">
                     <div class="alert alert-danger" role="alert">
                      <h4 class="alert-heading">Tempo de acesso expirado  
                        &nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 20 20">
                         <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                         <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                       </svg>
                      </h4>
                      <p> O seu tempo de acesso ao sistema expirou. 
                        <br/>Não perca o acesso às suas informações e gerencie de forma eficaz a sua empresa.
                        <br/>Faça a sua assinatura.
                      </p>
                      <hr />
                      <p class="mb-0"><b>Veja um dos planos abaixo e o que melhor
                        se encaixa de acordo com a sua necessidade:
                        </b>
                      </p>
                      <br/>
                      <div className="form-group col-lg-12">
                        <select
                          id="tipoPlano"
                          name="tipoPlano"
                          className="form-control"
                          onChange={onSelectPlano}
                          value={valorPlano}
                        >
                          <option value={49.9} selected={true}>Mensal - R$ 49,90</option>
                          <option value={180}>Semestral - R$ 180,00</option>
                          <option value={360}>Anual     - R$ 360,00</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-6">
                        <label for="cupom">Cupom de desconto:</label>
                        <input
                          type='text'
                          id="cupom"
                          name="cupom"
                          className="form-control"
                          onChange={onChangeCupom}
                          value={cupomSearch}
                          placeholder="Digite aqui um cupom de desconto"
                        />                          
                      </div>

                      <div className="form-group col-lg-8"> 
                      {loadingAplicarCupom
                      ? <button type='button' disabled={true} className='btn btn-dark'>Processando...<Spinner/></button>                          
                      : <button type='button' className='btn btn-dark' onClick={aplicarCupom}>Aplicar Cupom</button>                          
                      }                                                                    
                        <br/>
                        <br/>
                         {cupom > 0 
                          ? <span>Será aplicado um desconto de <strong>{cupom}%</strong> sobre o valor selecionado.
                             <br/> De <b className='text-muted'>
                                         <s style={{"color":"red"}}>
                                            R${CurrencyUtils.DecimalParaString(parseFloat(valorPlano).toFixed(2))}
                                         </s>
                                       </b> 
                                       <b className='text-muted'>
                                           - por: R${CurrencyUtils.DecimalParaString(parseFloat(valorPlano - (valorPlano * cupom / 100) ).toFixed(2)) }.
                                        </b> 
                                        </span>
                          : null
                         }                         
                      </div>

                     </div>
                    </div>                    
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row' >
                      <div className='col-3'>
                         <button type="button" className="btn btn-secondary" onClick={Passo1ParaPasso2}>Pagar com cartão</button>               
                      </div>
                      <div className='col-3'>
                         <button type="button" className="btn btn-info" onClick={Passo1ParaPasso3}>Pagar com pix</button>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classNames({
                                          "d-none": passo1 === true || passo3 === true,
                                          "row": passo2 === true
                                          }
                                          )
                             }>
                  <div className="col-lg-12">
                    <h5>Por favor, revise as informações e informe abaixo os dados de pagamento:</h5>
                    <div class="alert alert-warning" role="alert">
                      <h6>Plano selecionado: <b>{planoDescricao}</b></h6>
                      <h6>Valor: <b>R${CurrencyUtils.StringParaDecimal(CurrencyUtils.DecimalParaString(valorPlano))}</b></h6> 
                    </div>
                    
                    <hr />
                    <div id="PaymentForm" className="row">
                      <div className="col-6">
                        <div className="form-row">
                          <div className="form-group col-lg-12">
                            <label>Número do cartão</label>
                            <input
                              type="tel"
                              inputMode="numeric"
                              autoComplete="cc-number"
                              id="form-checkout__cardNumber"
                              name="cardNumber"
                              onChange={(event) => {
                                handleInputChange(event)
                                const{value} = event.target;
                                event.target.value = normalizeCardnumber(value)
                              }}
                              onFocus={handleInputFocus}
                              maxLength={19}
                              required={true}
                              className="form-control text-left" />
                              {exibeMensagemNumeroCartao && <span style={{"color":"red"}}>Número de cartão inválido</span>}
                             
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-lg-12">
                            <label>Titular do cartão</label>
                            <input
                              type="text"
                              id="form-checkout__cardholderName"
                              name="cardholderName"
                              onChange={handleInputChange}
                              onFocus={handleInputFocus}
                              className="form-control text-left"
                              required={true}
                              maxLength={30} />
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-lg-3">
                            <label>Mês</label>
                            <input
                              type="tel"
                              inputMode="numeric"
                              autoComplete="cc-number"
                              id="form-checkout__cardExpirationMonth"
                              name="cardExpirationMonth"
                              onChange={(event) => {
                                handleInputChange(event)
                                const{value} = event.target
                                event.target.value = retornaSomenteNumeros(value)
                              }}
                              onFocus={handleInputFocus}
                              className="form-control text-left"
                              required={true}
                              maxLength={2} />
                          </div>

                          <div className="form-group col-lg-3">
                            <label>Ano</label>
                            <input
                              type="tel"
                              inputMode="numeric"
                              autoComplete="cc-number"
                              id="form-checkout__cardExpirationYear"
                              name="cardExpirationYear"
                              onChange={(event) => {
                                handleInputChange(event)
                                const{value} = event.target
                                event.target.value = retornaSomenteNumeros(value)
                              }}
                              onFocus={handleInputFocus}
                              className="form-control text-left"
                              required={true}
                              maxLength={2} />
                          </div>

                          <div className="form-group col-lg-6">
                            <label>Código de segurança</label>
                            <input
                              type="tel"
                              inputMode="numeric"
                              autoComplete="cc-number"
                              id="form-checkout__securityCode"
                              name="securityCode"
                              onChange={(event) => {
                                handleInputChange(event)
                                const{value} = event.target
                                event.target.value = retornaSomenteNumeros(value)
                              }}
                              onFocus={handleInputFocus}
                              className="form-control text-left"
                              required={true}
                              maxLength={4} />
                          </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12">
                                 <label>Parcelas</label>
                                        <select
                                          id="form-checkout__installments"
                                          name="installments" 
                                          className="form-control" 
                                        />
                            </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <Cards
                          cvc={cvc}
                          expiry={expiry}
                          focused={focus}
                          name={name}
                          number={number}
                          className="col-lg-6"
                          locale={{ valid: 'Válido até' }} />
                      </div>
                                                                                    
                    </div>

                    <h5>Dados do pagador</h5>
                     <hr/>
                    <div className="row" id="divDadosPagador">
                         <div className="col-12">
                              <div className="form-row">
                                 <div className="form-group col-4">
                                   <label>Cpf</label>
                                  <input
                                      type="text"
                                      id="form-checkout__identificationNumber"
                                      name="identificationNumber" 
                                      className="form-control" 
                                      maxLength={11}
                                      required={true}
                                      onChange={(event) =>{
                                        const{value} = event.target
                                        event.target.value = retornaSomenteNumeros(value)
                                      }}
                                    />
                                 </div>      

                                 <div className="form-group col-6">
                                   <label>E-mail</label>
                                      <input
                                        type="text"
                                        id="form-checkout__cardholderEmail"
                                        name="cardholderEmail"
                                        onChange={handleInputChange}
                                        onFocus={handleInputFocus} 
                                        className="form-control"
                                        required={true}
                                      />  
                                 </div>                                               
                                                                              
                                  <select
                                          id="form-checkout__issuer"
                                          name="issuer"
                                          className="d-none" 
                                    />
                                  <select
                                      id="form-checkout__identificationType"
                                      name="identificationType" 
                                      className="d-none" 
                                    />
                              </div>
                         </div>
                    </div>

                    <br/>
                    <div className="row">
                      <div className="col-12">
                          <button type="button" className="btn btn-secondary" onClick={VoltarPaginaValores}>Voltar</button>
                          {processandoPagamento 
                           ? <button className="btn btn-warning" disabled={true} type="button" style={{"float":"right"}}>Processando pagamento...<Spinner/></button>
                           : <button className="btn btn-warning" type="submit" id="form-checkout__submit" style={{"float":"right"}}>Efetuar pagamento</button> }
                          
                      </div>                        
                    </div>
                  </div>
                </div>
                
                <div className={classNames({
                                          "d-none": passo1 === true || passo2 === true,
                                          "row": passo3 === true
                                          }
                                          )
                             }>

                   <div className='col-lg-12'>
                    <h5>Por favor, revise as informações e informe abaixo os dados de pagamento:</h5>
                      <div class="alert alert-warning" role="alert">
                        <h6>Plano selecionado: <b>{planoDescricao}</b></h6>
                        <h6>Valor: <b>R${CurrencyUtils.StringParaDecimal(CurrencyUtils.DecimalParaString(valorAtualizadoPix))}</b></h6>
                        <h5>Forma de pagamento: Pix</h5> 
                      </div>
                    
                    <hr />

                    <div className='row' id="dadosPagador">
                         <div className='col-12'>

                              <div className='form-row'>
                                 <div className="form-group col-6">
                                   <label>Cpf</label>
                                      <input
                                        type="text"
                                        id="cpf"
                                        name="cpf"
                                        onChange={(e) => {
                                           const {value} = e.target;
                                           setcpf(value)
                                        }}
                                        onFocus={handleInputFocus} 
                                        className="form-control"                                        
                                        value={cpf}
                                      />  
                                 </div>

                                 <div className="form-group col-6">
                                   <label>E-mail</label>
                                      <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        onChange={(e) => {
                                           const {value} = e.target;
                                           setEmail(value)
                                        }}
                                        onFocus={handleInputFocus} 
                                        className="form-control"                                        
                                        value={email}
                                      />  
                                 </div>

                             </div>                             

                         </div>
                    </div>

                    <div className='row' id="divDadosQrCode">
                         <div className='col-12 text-center'>                         
                            { qrCode !== '' &&  <div><h6><strong>Escaneie ou copie o código abaixo para efetuar o pagamento</strong></h6>                            
                              <img height={180} alt='Qr Code' src={`data:image/jpeg;base64,${qrCode}`}></img>
                              </div>                            
                            }
                         </div>
                    </div>

                    <div className='row' id="divCopiarDadosQrcode">
                      { qrCode !== '' && <div className='col-12 text-center'>                          
                          {/* <button className="btn btn-warning"
                          onClick={copiarchavePix}
                          >Copiar chave Pix</button>   */}
                          <br/>
                          <label style={{"fontSize":"12px"}} id="qrCodeHidden" value={qrCodeCopy}>{qrCodeCopy}</label>                       
                       </div>
                      }
                       {/* <div className='col-6 text-center'>                          
                          <button className="btn btn-success">Quero mudar o plano</button>
                       </div>    */}

                     
                    </div>
                    
                    <br/>

                    <div className="row">
                      <div className="col-12">
                          <button type="button" className="btn btn-secondary" onClick={IrAoPasso1}>Voltar</button>
                          {processandoPagamento 
                           ? <button className="btn btn-warning" disabled={true} type="button" style={{"float":"right"}}>Gerando Qr Code...<Spinner/></button>
                           : qrCode === '' && <button className="btn btn-warning" 
                                     type="button" 
                                     id="btn_gerar_Qr" 
                                     onClick={GerarQrCode}
                                     style={{"float":"right"}}>Gerar Qr Code
                            </button> }
                          
                      </div>                        
                    </div>

                   </div>
                </div>
                
                </>  
                                     
          }
          
                                     
           
         </form>

           : <h3>Fazer html cadastro</h3>}       
        </>
    )
}

export default CadastroContrato;