import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import usuario from '../../types/usuario';
import UsuarioService from '../../services/UsuarioService';
import StringUtils from '../../Utils/StringUtils';
import classNames from 'classnames';

const CadastroUsuario: React.FC = () => {
    
    const[etapa1, setEtapa1] = useState(true)
    const[etapa2, setEtapa2] = useState(false)

    const[exiberow, setExibeRow] = useState(true)

    let usuarioService = new UsuarioService();

    const txtNomeCad = useRef<null | HTMLElement>(null)

    const [processandoCadastro, setProcessandoCadastro] = useState(false)

     const[formData, setFormData] = useState<usuario>({
         Ativo: "Sim",
         Data_Cadastro: new Date().toLocaleDateString(),
         Data_Inativacao: "",
         Email: "",
         GrupoUsuariosLocucaoId: 0,
         Motivo_Inativacao: "",
         Nome: "",
         Senha: "",
         ConfirmarSenha: "",
         Id: 0,
         Cpf_Cnpj: ""
     })

     const handleInputchange = (event: ChangeEvent<HTMLInputElement>) => {
        const {name , value} = event.target;
        setFormData({...formData, [name]: value});  

     };

     const salvarUsuario = async() => {

        let formularioEhValido = validarFormulario(formData)

        if(formularioEhValido)
        {
            setProcessandoCadastro(true)

            let cadastrou = await usuarioService.cadastrar(formData);
    
            if(cadastrou)
            {
                toast("Cadastro efetuado com sucesso",{
                    type:"success"
                })
    
                setTimeout(() => {
                    window.location.reload();//Redireciona para o dashboard pois o serviço acima já tokenizou
                }, 2000);
            }
    
            setProcessandoCadastro(false)
        }
     }
     
     const validarFormulario = (form: usuario): boolean => {

         if(StringUtils.isNullOrEmpty(form.Nome))
         {
            toast("Informe o login",{
                type:"warning"
            })

            return false;
         }

         if(StringUtils.isNullOrEmpty(form.Email))
         {
            toast("Informe o e-mail",{
                type:"warning"
            })

            return false;
         }

        //  if(!emailEhValido(form.Email))
        //  {
        //     toast("O e-mail informado está no formato incorreto",{
        //         type:"warning"
        //     })

        //     return false;
        //  }

         if(StringUtils.isNullOrEmpty(form.Senha) || 
            StringUtils.isNullOrEmpty(form.ConfirmarSenha)
         )
         {
            toast("Informe as senhas corretamente",{
                type:"warning"
            })

            return false;
         }

         if(form.Senha !== form.ConfirmarSenha)
         {
            toast("As senhas estão diferentes.",{
                type:"warning"
            })

            return false;
         }

         if(form.Senha.length < 8)
         {
            toast("A senha deve conter pelo menos 8 caracteres.",{
                type:"warning"
            })

            return false;
         }

        //  if(StringUtils.isNullOrEmpty(form.Cpf_Cnpj))
        //  {
        //     toast("Informe o cpf",{
        //         type:"warning"
        //     })

        //     return false;
        //  }

        //  if(form.Cpf_Cnpj!.length < 11 || 
        //     (form.Cpf_Cnpj!.length === 12 || 
        //      form.Cpf_Cnpj!.length === 13
        //     ))
        //     {
        //         toast("O campo cpf/cnpj está no formato incorreto",{
        //             type:"warning"
        //         })
    
        //         return false;
        //     }

        //  if(form.Cpf_Cnpj!.length === 11)
        //  {
        //      if(!cpfEhValido(String(form.Cpf_Cnpj)))
        //      {
        //         toast("O cpf informado é inválido",{
        //             type:"warning"
        //         })
    
        //         return false; 
        //      }
        //  }   
         
        //  if(form.Cpf_Cnpj!.length === 14)
        //  {
        //      if(!cnpjEhValido(String(form.Cpf_Cnpj)))
        //      {
        //         toast("O cnpj informado é inválido",{
        //             type:"warning"
        //         })
    
        //         return false; 
        //      }
        //  } 

         return true;
     }

     const emailEhValido = (email:string) => {
        let emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
        return emailRegex.test(email)
    }

    const cpfEhValido = (cpf:any) => {
        if (typeof cpf !== 'string') return false
        cpf = cpf.replace(/[^\d]+/g, '')
        if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
        cpf = cpf.split('')
        const validator = cpf
            .filter((digit:any, index:any, array:any) => index >= array.length - 2 && digit)
            .map( (el:any) => +el )
        const toValidate = (pop:any) => cpf
            .filter((digit:any, index:any, array:any) => index < array.length - pop && digit)
            .map((el:any) => +el)
        const rest = (count: any, pop:any) => (toValidate(pop)
            .reduce((soma:any, el:any, i:any) => soma + el * (count - i), 0) * 10) % 11 % 10
        return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1])
    }

    const cnpjEhValido = (cnpj:any) => {

        cnpj = cnpj.replace(/[^\d]+/g,'');
            
        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" || 
            cnpj === "11111111111111" || 
            cnpj === "22222222222222" || 
            cnpj === "33333333333333" || 
            cnpj === "44444444444444" || 
            cnpj === "55555555555555" || 
            cnpj === "66666666666666" || 
            cnpj === "77777777777777" || 
            cnpj === "88888888888888" || 
            cnpj === "99999999999999")
            return false;
            
        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(0))
            return false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(1))
            return false;
            
        return true;
    }

    const retornaSomenteNumeros = (value:any) => {
        return String(value).replace(/[^0-9]/g, '')
    }

    const passo1ParaPasso2 = () => {
        setEtapa1(false)
        setEtapa2(true)
    }

    const passo2ParaPasso1 = () => {
        setEtapa1(true)
        setEtapa2(false)
    }

     useEffect(() => {
        txtNomeCad.current!.focus(); 
     }, [])

     return (
         <>
          <div className={classNames({
                "d-none": etapa1 === false,
                "row": exiberow === true
              }
              )}>
               <div className="col-lg-12">
                   <div className="card shadow-sm">
                       <div className="card-body">
                           <div className="alert alert-secondary" role="alert">                              
                              <p>                               
                                 Seja bem vindo(a) ao sistema de gerenciamento de seus trabalhos em locução, falta pouco para você fazer o cadastro e ingressar na plataforma.
                                Após o cadastro, você terá <u>20 dias gratuitos</u> para experimentar todas as funcionalidades da plataforma.
                                <br/>Quando terminar este período, o seu acesso será interrompido, tendo a necessidade de uma assinatura com os seguintes valores:<br/>
                                <table className='table table-striped table-bordered'>
                                     <thead className='thead-dark'>
                                        <tr>
                                            <th scope="col">Plano</th>
                                            <th scope="col">Valor</th>
                                        </tr>
                                     </thead>
                                     <tbody>
                                        <tr>
                                            <td>Mensal</td>
                                            <td>R$30,00</td>
                                        </tr>
                                        <tr>
                                            <td>Semestral</td>
                                            <td>R$180,00</td>
                                        </tr>
                                        <tr>
                                            <td>Anual</td>
                                            <td>R$360,00</td>
                                        </tr>
                                     </tbody>
                                </table>
                             </p>                             
                                 Enquanto isso, fique à vontade para avaliar esta plataforma que vai agregar muito no seu dia-a-dia com métricas de prospecção, faturamento, castings e muito mais.                                 
                                 Não se preocupe: se optar por não renovar a assinatura, cancelar ou retomar posteriomente, seus dados
                                permanecerão sem qualquer alteração.         
                           </div>
                           <div className="text-right">
                                <button type="button" className="btn" style={{"background": "#DE6262", "color":"#fff"}} onClick={passo1ParaPasso2}>Próximo</button>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <div className={classNames({
                "d-none": etapa2 === false,
                "row": exiberow === true
              }
              )}>
            <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-lg-6">
                            <label>Login</label>
                                <input type="text" 
                                className="form-control text-left" 
                                id="Nome" 
                                name="Nome" 
                                onChange={handleInputchange}
                                value={formData.Nome}
                                ref={txtNomeCad as any} 
                                maxLength={50}
                                />                            
                        </div>
                        <div className="form-group col-lg-6">
                            <label>E-mail</label>
                                <input type="text" 
                                className="form-control text-left" 
                                id="Email" 
                                name="Email" 
                                onChange={handleInputchange}
                                value={formData.Email}
                                maxLength={50}
                                />
                        </div>
                    </div>
                 
                    <div className="form-row">
                        <div className="form-group col-lg-6">
                            <label>Senha</label>
                                <input type="password" 
                                className="form-control text-left" 
                                id="Senha" 
                                name="Senha" 
                                onChange={handleInputchange}
                                value={formData.Senha}
                                maxLength={30}
                                />
                        </div>
                        <div className="form-group col-lg-6">
                            <label>Confirmar senha</label>
                                <input type="password" 
                                className="form-control text-left" 
                                id="ConfirmarSenha" 
                                name="ConfirmarSenha" 
                                onChange={handleInputchange}
                                value={formData.ConfirmarSenha}
                                maxLength={30}
                                />
                        </div>
                    </div>

                    {/* <div className="form-row">
                        <div className="form-group col-lg-6">
                            <label>Cpf/Cnpj</label>
                                <input type="text" 
                                className="form-control text-left" 
                                id="Cpf_Cnpj" 
                                name="Cpf_Cnpj" 
                                placeholder="Somente números"
                                maxLength={14}
                                onChange={(event) => { 
                                        const {name, value} = event.target;
                                        event.target.value  = retornaSomenteNumeros(value);
                                        setFormData({...formData, [name]: retornaSomenteNumeros(value)});
                                }}
                                value={formData.Cpf_Cnpj}
                                />
                        </div>
                    </div> */}
     
                    <div className="text-center">
                        <button className="btn btn-md btn-secondary" type="button" onClick={passo2ParaPasso1}>Voltar</button>
                        {processandoCadastro
                        ? <button className="btn btn-md" style={{"marginLeft":"30px","background": "#DE6262", "color":"#fff"}} disabled={true} type="button">Processando...<Spinner/></button>
                        : <button className="btn btn-md" style={{"background": "#DE6262", "color":"#fff","marginLeft":"30px"}} type="button" onClick={salvarUsuario}>Cadastrar</button>
                        }
                        
                    </div>

                    </div>
                </div>
            </div>
          </div>
                             
         </>
     );
}

export default CadastroUsuario;