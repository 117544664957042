import { Bar } from 'react-chartjs-2';
import Dashboard from '../../types/Dashboard';
import { Chart as ChartJS, CategoryScale, Tooltip, Legend, LinearScale, BarElement } from 'chart.js';
import { CurrencyUtils } from '../../Utils/CurrencyUtils';

ChartJS.register(CategoryScale, Tooltip, Legend, LinearScale, BarElement);

const ChartMensal = (dash:{dashboard: Dashboard}) => {

  const {dashboard} = dash;  
  
  
  const obterMesCorrente = (): string => {

    const mes = new Date().getMonth(); 

    switch(mes)
    {
      case 0 : return "Janeiro";
      case 1 : return "Fevereiro";
      case 2 : return "Março";
      case 3 : return "Abril";
      case 4 : return "Maio";
      case 5 : return "Junho";
      case 6 : return "Julho";
      case 7 : return "agosto";
      case 8 : return "Setembro";
      case 9 : return "Outubro";
      case 10 : return "Novembro";
      case 11 : return "Dezembro";
      default: return "janeiro";
    }
  }

  let chartData = {
      labels: [
         "Mês - " + obterMesCorrente()
      ],
      datasets:[
          {
             label: 'Valores Recebidos',
             data:[
                CurrencyUtils.DecimalParaString(dashboard.valorConsolidadoMes)
             ],
             backgroundColor:[
                 'rgba(99, 255, 112, 0.76)'
             ]
          },
          {
             label: 'Valores a Receber',
             data:[
                CurrencyUtils.DecimalParaString(dashboard.valorProjetadoMes)
             ],
             backgroundColor:[
                 'rgba(211, 192, 196, 0.767)'
             ]
          },

      ]
  };


 return(
     <>        
        <Bar
           data={chartData}
        />                
     </>
 )
}

export default ChartMensal;