import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Route, Routes, Navigate } from "react-router-dom";
import CadastroClientes from '../../pages/Clientes/Cadastro';
import CadastroProspectados from '../../pages/Prospectados/Cadastro';
import Login from '../../pages/Autenticacao/login';
import Logout from '../../pages/Autenticacao/logout';
import Dashboard from '../../pages/Dashboard';
import Topbar from "./Topbar";
import UsuarioUtils from "../../Utils/UsuarioUtils";
import CadastroJob from "../../pages/Jobs/CadastroJob";
import CadastroCasting from "../../pages/Jobs/CadastroCasting";
import MetasFC from "../../pages/Config/usuarios/Metas";
import PainelDadosUsuario from "../../pages/Autenticacao/PainelDadosUsuario";
import ClientesSemContato from "../../pages/Reports/Clientes/ClientesSemContato";
import JobsPagos from "../../pages/Reports/Jobs/Faturamento/JobsPagos";
import JobsPendentesPagamento from "../../pages/Reports/Jobs/PendentePagamento/JobsPendentesPagamento";
import ContactFC from "../../pages/Communication/Contact";
import IndicacaoPrincipal from "../../pages/Indicacoes/Principal";
import NotificacoesPrincipal from "../../pages/Notificacoes/Principal";

const usuarioUtils = new UsuarioUtils();

const PrivateRoute = ({ children }) => {
  const authed = usuarioUtils.TokenEhValido();
  return authed ? children : <Navigate to="/Efetuar-LogOff" />;
};

const routes = [
  // Rotas públicas
  { path: "/Efetuar-Login", element: <Login /> },
  { path: "/Efetuar-Contato", element: <ContactFC /> },
  { path: "/Efetuar-LogOff", element: <Logout /> },

  // Rotas privadas
  { path: "/", element: <PrivateRoute><Dashboard /></PrivateRoute> },
  { path: "/Gerenciar-Clientes", element: <PrivateRoute><CadastroClientes /></PrivateRoute> },
  { path: "/Gerenciar-Prospectados", element: <PrivateRoute><CadastroProspectados /></PrivateRoute> },
  { path: "/Efetuar-Cadastro-Job", element: <PrivateRoute><CadastroJob /></PrivateRoute> },
  { path: "/Efetuar-Cadastro-Casting", element: <PrivateRoute><CadastroCasting /></PrivateRoute> },
  { path: "/Efetuar-Configuracao-Metas", element: <PrivateRoute><MetasFC /></PrivateRoute> },
  { path: "/Efetuar-Configuracao-Cadastro", element: <PrivateRoute><PainelDadosUsuario /></PrivateRoute> },
  { path: "/Indicacoes", element: <PrivateRoute><IndicacaoPrincipal /></PrivateRoute> },
  { path: "/Ver-Notificacoes", element: <PrivateRoute><NotificacoesPrincipal /></PrivateRoute> },
  { path: "/Rel-Clientes-Sem-Contato", element: <PrivateRoute><ClientesSemContato /></PrivateRoute> },
  { path: "/Rel-Jobs-Pagos", element: <PrivateRoute><JobsPagos /></PrivateRoute> },
  { path: "/Rel-Jobs-Pendentes-Pagamento", element: <PrivateRoute><JobsPendentesPagamento /></PrivateRoute> },
];

const Content = ({ sidebarIsOpen, toggleSidebar }) => (
  <Container fluid className={classNames("content", { "is-open": sidebarIsOpen })}>
    <Topbar toggleSidebar={toggleSidebar} tituloMenus={sidebarIsOpen ? "Ocultar menus" : "Exibir menus"} />
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  </Container>
);

export default Content;
