import { useEffect } from "react";
import { ChangeEvent, useState } from "react";
import ChartMensal from "../../components/chart/chartMensal";
import ChartAnual from "../../components/chart/chartAnual";
import DashboardService from "../../services/DashboardService";
import Dashboard, { ModoExibicaoDashboard } from "../../types/Dashboard";
import ResponseResult from "../../types/ResponseResult";
import { Spinner } from "reactstrap";
import JanelaModal from "../shared/JanelaModal";
import Casting from "./Components/Casting";
import TotalJobs from "./Components/TotalJobs";
import JobsAPagar from "./Components/JobsAPagar";
import JobsPagos from "./Components/JobsPagos";
import ClientesSemContato from "./Components/Clientes_Sem_Contato";
import ProspectadosSemContato from "./Components/Prospectados_Sem_Contato";
import TotalDeProspectados from "./Components/TotalDeProspectados";
import ChartSemanal from "../../components/chart/chartSemanal";
import DashboardChartPizza from "../../types/DashboardChartPizza";
import ChartPizza from "../../components/chart/chartPizza";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBriefcase, faMoneyCheckAlt, faMoneyBillWave } from "@fortawesome/free-solid-svg-icons";
import './Dashboard.css'
import UsuarioUtils from "../../Utils/UsuarioUtils";

const DashboardFC: React.FC = () => {
    
    var dashboardService = new DashboardService();
    var usuarioUtils = new UsuarioUtils();

    const [modoExibicaoDashboard, setModoExibicaoDashboard] = useState<ModoExibicaoDashboard>(ModoExibicaoDashboard.mensal)
    const [modoAnoDashboard, setModoAnoDashboard] = useState<number>(new Date().getFullYear());
    const [desativarModoExibicaoDashboard, setDesativarModoExibicaoDashboard] = useState(false);
    const [opcoesAnoDashboard, setOpcoesAnoDashboard] = useState<number[]>([])
    const [Dashboard, setDashboard] = useState<Dashboard>();
    const [loading, setLoading] = useState<boolean>(false);
    const [dashboardChartPizza, setDashboardChartPizza] = useState<DashboardChartPizza[]>([])
    const [dashboardChartPizzaPorCidade, setDashboardChartPizzaPorCidade] = useState<DashboardChartPizza[]>([])
    const [qtdDiasEmAtraso, setQtdDiasEmAtraso] = useState<number>(0)

    const [montarModais, setMontarModais]                                = useState(false);
    const [showModalCastings, setShowModalCastings]                      = useState(false);
    const [showModalTotalJobs, setShowModalTotalJobs]                    = useState(false);
    const [showModalJobsAPagar, setShowModalJobsAPagar]                  = useState(false);
    const [showModalJobsPagos, setShowModalJobsPagos]                    = useState(false);
    const [showModalClientesSemContato, setShowModalClientesSemContato]  = useState(false);
    const [showModalProspectadosSemContato, setShowModalProspectadosSemContato]  = useState(false);
    const [showModalProspectadosCadastrados, setShowModalProspectadosCadastrado]  = useState(false);

    const handleCloseModalCastings = () => setShowModalCastings(false);
    const handleShowModalCastings = () =>  setShowModalCastings(true);

    const handleCloseModalJobs = () => setShowModalTotalJobs(false);
    const handleShowModalJobs = () =>  setShowModalTotalJobs(true);

    const handleCloseModalJobsAPagar = () => {
                                                console.log('fechou modal')
                                                carregarInformacoesDashboard();
                                                setShowModalJobsAPagar(false);
                                             };
    const handleShowModalJobsAPagar  = () =>  setShowModalJobsAPagar(true);
    
    const handleCloseModalJobsPagos = () => setShowModalJobsPagos(false);
    const handleShowModalJobsPagos  = () =>  setShowModalJobsPagos(true);

    const handleCloseModalClientesSemContato = () => setShowModalClientesSemContato(false);
    const handleShowModalClientesSemContato  = () =>  setShowModalClientesSemContato(true);

    const handleCloseModalProspectadosSemContato = () => setShowModalProspectadosSemContato(false);
    const handleShowModalProspectadosSemContato  = () =>  setShowModalProspectadosSemContato(true);

    const handleCloseModalProspectadosCadastrados = () => setShowModalProspectadosCadastrado(false);
    const handleShowModalProspectadosCadastrados  = () =>  setShowModalProspectadosCadastrado(true);

    useEffect(() => {
        carregarInformacoesDashboard();
        setMontarModais(true)

        let tamanhoDivPizza = document.getElementById('divPizza')?.style.height
        let divMetricas = document.getElementById('divMetricas')

        if(divMetricas && tamanhoDivPizza)           
           divMetricas.style.height = tamanhoDivPizza;

           console.log(modoExibicaoDashboard)

        let parametrosUsuario = usuarioUtils.ObterParametrosUsuario();
        setQtdDiasEmAtraso(parametrosUsuario?.DiasSemContatoClientesProspectados);

        setarOpcoesAnoDashboard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[modoExibicaoDashboard, modoAnoDashboard]);

    const setarOpcoesAnoDashboard = () => 
    {
       let anoAtual = new Date().getFullYear();
       let inicio = 2021;

       let result : number[] = [];

       while(inicio <= anoAtual)
       {
          result.push(inicio)

          inicio ++;
       }

       setOpcoesAnoDashboard(result);
    }

    const handleSelectModoAnoDashboard = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;        
        setModoAnoDashboard(parseInt(value));  
        
        let anoSelecionado = parseInt(event.target.value)
        let anoAtual = new Date().getFullYear();
        setModoAnoDashboard(anoSelecionado)

        if(anoSelecionado < anoAtual)
        {
            setModoExibicaoDashboard(ModoExibicaoDashboard.anual)
            setDesativarModoExibicaoDashboard(true)                        
        }
        else
            setDesativarModoExibicaoDashboard(false)
    }

    const handleSelectModoExibicaoDashboard = (event: ChangeEvent<HTMLSelectElement>) => {
        const {value} = event.target;
        setModoExibicaoDashboard(parseInt(value));         
    }

    const carregarInformacoesDashboard = async() => {
        
        let exibirSemanal: boolean = false;
        let exibirMensal: boolean = false;
        let exibirAnual: boolean = false;

        setLoading(true);

        if(modoExibicaoDashboard === ModoExibicaoDashboard.mensal)
            exibirMensal = true;   
        else
        if(modoExibicaoDashboard === ModoExibicaoDashboard.anual)
            exibirAnual   = true;
        else
            exibirSemanal = true;                               

        let responseResult: ResponseResult = await dashboardService.ObterDados(exibirSemanal, 
                                                                               exibirMensal, 
                                                                               exibirAnual,
                                                                               modoAnoDashboard);

        if(responseResult.status === 200)
        {
           setDashboard(responseResult.data as Dashboard);
        } 
        
        let responseResultDashPizza: ResponseResult = await dashboardService
                                                           .ObterDashTipoJobsPizza(exibirSemanal, 
                                                                           exibirMensal, 
                                                                           exibirAnual,
                                                                           modoAnoDashboard);

        if(responseResultDashPizza.status === 200)
        {
            setDashboardChartPizza(responseResultDashPizza.data as DashboardChartPizza[])
        }  
        
        let responseResultDashPizzaJobPorCidade: ResponseResult = await dashboardService
                                                                    .ObterDashJobPorCidadesPizza(exibirSemanal, 
                                                                                    exibirMensal, 
                                                                                    exibirAnual,
                                                                                    modoAnoDashboard);

        if(responseResultDashPizzaJobPorCidade.status === 200)
        {
            setDashboardChartPizzaPorCidade(responseResultDashPizzaJobPorCidade.data as DashboardChartPizza[])
        }
        
        setLoading(false);
    }

    const construirModais = () => 
    {
        if(montarModais)
        {
            return(
              <>  
                <JanelaModal
                        id="modalCastings"
                        isOpen={showModalCastings}
                        onHide={handleCloseModalCastings}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de castings não aprovados"
                        component={<Casting modoExibicaoDashboard={modoExibicaoDashboard}
                                            modoAnoDashboard={modoAnoDashboard} />}
                />

                <JanelaModal
                        id="modalTotalJobs"
                        isOpen={showModalTotalJobs}
                        onHide={handleCloseModalJobs}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações do total de Jobs"
                        component={<TotalJobs modoExibicaoDashboard={modoExibicaoDashboard}
                                              modoAnoDashboard={modoAnoDashboard}/>}
                 />

                <JanelaModal
                        id="modalJobsAPagar"
                        isOpen={showModalJobsAPagar}
                        onHide={handleCloseModalJobsAPagar}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de Jobs a pagar"
                        component={<JobsAPagar modoExibicaoDashboard={modoExibicaoDashboard}
                                               modoAnoDashboard={modoAnoDashboard}/>}
                 />

                <JanelaModal
                        id="modalJobsPagos"
                        isOpen={showModalJobsPagos}
                        onHide={handleCloseModalJobsPagos}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de Jobs pagos"
                        component={<JobsPagos modoExibicaoDashboard={modoExibicaoDashboard}
                                              modoAnoDashboard={modoAnoDashboard}/>}
                 />

                 <JanelaModal
                        id="modalClientesSemContato"
                        isOpen={showModalClientesSemContato}
                        onHide={handleCloseModalClientesSemContato}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de Clientes sem contato"
                        component={<ClientesSemContato/>}
                 />

                  <JanelaModal
                        id="modalProspectadosSemContato"
                        isOpen={showModalProspectadosSemContato}
                        onHide={handleCloseModalProspectadosSemContato}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de Prospectados sem contato"
                        component={<ProspectadosSemContato/>}
                 />

                  <JanelaModal
                        id="modalProspectadosCadastrados"
                        isOpen={showModalProspectadosCadastrados}
                        onHide={handleCloseModalProspectadosCadastrados}
                        showSaveButton={false}
                        size="xl"
                        tituloModal="Informações de Prospectados cadastrados"
                        component={<TotalDeProspectados periodo={modoExibicaoDashboard}
                                                        modoAnoDashboard={modoAnoDashboard} />}
                 />
            </>
            )
        }
    } 

    return(
        <>          
          <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-5 text-left">
                        <h6 className="mt-0">Informe o ano que deseja efetuar a pesquisa:</h6>
                    </div>
                    <div className="col-lg-2 text-left">                        
                        <select
                           id="slcModoAno"
                           name="slcModoAno"
                           value={modoAnoDashboard}
                           className="form-control"
                           onChange={handleSelectModoAnoDashboard}
                           
                        >   
                            {
                                opcoesAnoDashboard.map(m => {
                                    if(m === new Date().getFullYear())
                                      return <option value={m} selected={true}>{m}</option>
                                    else
                                      return <option value={m}>{m}</option>
                                } )
                            }                         
                        </select>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-2 text-left">
                        <h4 className="mt-0">Dashboard</h4>
                    </div>
                    <div className="col-lg-3 text-left">
                        <select
                           id="slcModoExibicao"
                           name="slcModoExibicao"
                           value={modoExibicaoDashboard}
                           className="form-control"
                           onChange={handleSelectModoExibicaoDashboard}
                           disabled={desativarModoExibicaoDashboard}
                           
                        >                            
                            <option value={ModoExibicaoDashboard.mensal} selected={true}>Mensal</option>
                            <option value={ModoExibicaoDashboard.semanal}>Semanal</option>
                            <option value={ModoExibicaoDashboard.anual}>Anual</option>
                        </select>
                    </div>
                    <div className="col-lg-5 text-left">                
                            <button className="btn btn-secondary" onClick={carregarInformacoesDashboard}>
                                <i className="icon-refresh"></i>
                                Recarregar informações
                            </button>                                           
                    </div>
                  </div>

                 <div className="col-12">
                     <div className="row mt-2 h-200">  
                     {loading ? <div className="col-lg-12">
                                    <div className="card m-1 shadow" onClick={handleShowModalCastings}>
                                            <div className="card-body cardMouseHover">
                                                <h5>Carregando métricas... <Spinner/> </h5>                                                                                                                                        
                                            </div>
                                    </div>
                                </div>
                      :<>
                         <div className="col-lg-3 col-md-12 col-sm-12" style={{cursor: "pointer"}}>
                               <div className="card m-1 shadow h-100" onClick={handleShowModalCastings}>
                                       <div className="card-body cardMouseHover">
                                           { loading ? <Spinner/> 
                                             :<div className="row align-items-center">
                                                <div className="col-sm-8">
                                                    <h5 className="text-muted">Castings em aberto</h5>
                                                    <h3 className="mt-3 mb-3">{Dashboard?.qtdCastingsAbertos}</h3>
                                                </div>
                                                <div className="col-sm-4">
                                                    <FontAwesomeIcon color="gray" size="3x" icon={faEye} />
                                                </div>
                                             </div>  
                                           }
                                                                                                                                
                                       </div>
                                </div>
                           </div>
                           <div className="col-lg-3 col-md-12 col-sm-12" style={{cursor: "pointer"}}>
                               <div className="card m-1 shadow h-100" onClick={handleShowModalJobs}>
                                       <div className="card-body cardMouseHover">
                                          {loading ? <Spinner/>
                                           : <div className="row align-items-center">
                                                <div className="col-sm-8">
                                                    <h5 className="text-muted">Total de Jobs realizados</h5>
                                                    <h3 className="mt-3 mb-3">{Dashboard?.qtdJobs}</h3>
                                                </div>
                                                <div className="col-sm-4">
                                                        <FontAwesomeIcon color="gold" size="3x" icon={faBriefcase} />
                                               </div>
                                            </div>
                                          }                                                                                     
                                       </div>
                               </div>
                           </div>
                            <div className="col-lg-3 col-md-12 col-sm-12" style={{cursor: "pointer"}}>
                                <div className="card m-1 shadow h-100" onClick={handleShowModalJobsAPagar}>
                                       <div className="card-body cardMouseHover">
                                           {loading ? <Spinner/>
                                            : <div className="row align-items-center">
                                                <div className="col-sm-8">
                                                    <h5 className="text-muted">Jobs pendentes de pagamento</h5>
                                                    <h3 className="mt-3 mb-3">{Dashboard?.qtdjobsAPagar}</h3>
                                                </div>
                                                <div className="col-sm-4">
                                                        <FontAwesomeIcon color="red"  size="3x" icon={faMoneyCheckAlt} />
                                                </div>
                                             </div> 
                                           }
                                                                                   
                                       </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12" style={{cursor: "pointer"}}>
                                   <div className="card m-1 shadow h-100" onClick={handleShowModalJobsPagos}>
                                       <div className="card-body cardMouseHover">
                                           {loading ? <Spinner/>
                                            : <div className="row align-items-center">
                                                <div className="col-sm-8">
                                                   <h5 className="text-muted">Jobs recebidos</h5>
                                                   <h3 className="mt-3 mb-3">{Dashboard?.qtdPagos}</h3>
                                               </div>
                                                <div className="col-sm-4">
                                                    <FontAwesomeIcon color="green"  size="3x" icon={faMoneyBillWave} />
                                               </div>
                                             </div>
                                           }                                                                                     
                                       </div>
                                   </div>
                            </div>
                      </>
                     }
                           
                      </div>
                  </div>

                  <div className="row mt-4 text-center h-400">
                    {/* Area gráfico pizza */}
                     <div className="col-lg-4 text-center" id="divPizza">
                         <div className="card shadow d-flex">
                             <div className="card-body cardMouseHover align-items-center justify-content-center">
                                 <h5 style={{fontSize: '.8rem', 
                                             textTransform: 'uppercase',
                                             marginTop: '0',
                                             letterSpacing: '.02rem',
                                             textAlign: 'center'
                                            }} className="mb-3">Quantidade de jobs realizados por tipo
                                 </h5>  

                                 {
                                        loading ? <Spinner/> 
                                        :
                                        dashboardChartPizza !== undefined ?                                         
                                            <ChartPizza
                                             arrayPizza={dashboardChartPizza}                                             
                                        />
                                        : ""
                                 }                                                                           
                             </div>
                         </div>
                      </div>

                      <div className="col-lg-4 text-center" id="divPizza">
                         <div className="card shadow d-flex">
                             <div className="card-body cardMouseHover align-items-center justify-content-center">
                                 <h5 style={{fontSize: '.8rem', 
                                             textTransform: 'uppercase',
                                             marginTop: '0',
                                             letterSpacing: '.02rem',
                                             textAlign: 'center'
                                            }} className="mb-3">Quantidade de jobs realizados por localização
                                 </h5>  

                                 {
                                        loading ? <Spinner/> 
                                        :
                                        dashboardChartPizza !== undefined ?                                         
                                            <ChartPizza
                                             arrayPizza={dashboardChartPizzaPorCidade}                                             
                                        />
                                        : ""
                                 }                                                                           
                             </div>
                         </div>
                      </div>

                      {/* Area dos 4 cards*/}
                      <div className="col-xl-4 col-lg-4 col-sm-4" id="divMetricas">                          
                           {/* Area de clientes/prospectados sem contato */}
                            { Dashboard !== undefined ? 
                            <>
                              <div className="row">
                                        <div className="col-lg-6" style={{cursor: "pointer"}}>
                                            <div className="card m-1 shadow" onClick={handleShowModalClientesSemContato}>
                                                <div className="card-body cardMouseHover">
                                                    { loading ? <Spinner/>
                                                      :<div>                                                          
                                                          <h6 className="text-muted mt-4" style={{"textAlign":"center"}}>Clientes sem contato há mais de {qtdDiasEmAtraso} dias</h6>
                                                          <h4 className="mt-3 mb-3" style={{"textAlign":"center", "color":"red"}}>{Dashboard?.totalClientesSemContato}</h4>
                                                      </div>
                                                    }                                                    
                                                </div>
                                            </div>
                                        </div>                                       
                                        <div className="col-lg-6" style={{cursor: "pointer"}}>
                                            <div className="card m-1 shadow" onClick={handleShowModalProspectadosSemContato}>
                                                <div className="card-body cardMouseHover">
                                                    { loading ? <Spinner/>
                                                      :<div>                                                          
                                                          <h6 className="text-muted mt-4" style={{"textAlign":"center"}}>Prospectados sem contato há mais de {qtdDiasEmAtraso} dias</h6>
                                                          <h4 className="mt-3 mb-3" style={{"textAlign":"center", "color":"red"}}>{Dashboard?.totalProspectadosSemContato}</h4>
                                                      </div>
                                                    }                                                    
                                                </div>
                                            </div>
                                        </div>                                                                               
                                 </div>
                                 <div className="row">
                                        <div className="col-lg-6" style={{cursor: "pointer"}}>
                                            <div className="card m-1 shadow" onClick={handleShowModalProspectadosCadastrados}>
                                                <div className="card-body cardMouseHover">
                                                    { loading ? <Spinner/>
                                                      :<div>                                                               
                                                          <h6 className="text-muted mt-4" style={{"textAlign":"center"}}>Prospectados cadastrados</h6>
                                                          <h4 className="mt-4 mb-3" style={{"textAlign":"center", "color":"red"}}>{Dashboard?.qtdProspectados}</h4>
                                                      </div>
                                                    }                                                    
                                                </div>
                                            </div>
                                        </div>
                                 </div>
                                </>
                                                       
                        : null}

                           <div className="row">

                           </div>

                      </div>                      
                  </div>

                  {/* Area projeções de valores x valores atuais */}
                  { Dashboard !== undefined ?
                    <div className="row mt-2">
                        <div className="col-lg-12">
                          <div className="card shadow" style={{height:'100%'}}>
                             <div className="card-body">
                                 <h4 style={{fontSize: '.9rem', 
                                             textTransform: 'uppercase',
                                             marginTop: '0',
                                             letterSpacing: '.02rem',
                                             textAlign:'center'
                                            }} className="mb-3">Valores recebidos x Valores a receber
                                 </h4>  

                                 {
                                        loading ? <Spinner/> 
                                        :
                                        Dashboard !== undefined ?
                                             modoExibicaoDashboard === ModoExibicaoDashboard.mensal
                                            ? <ChartMensal dashboard={Dashboard!}/>
                                            : modoExibicaoDashboard === ModoExibicaoDashboard.semanal
                                            ? <ChartSemanal dashboard={Dashboard!}/>
                                            : <ChartAnual dashboard={Dashboard!}/>
                                        : ""
                                 }                                                                           
                             </div>
                         </div>
                      </div>
                    </div>
                   : null
                  }

                  {/* Area de metas */ }
                  {
                    Dashboard !== undefined && Dashboard.listaMetas.length > 0 ?
                                                            
                      <div>
                          <div className="row mt-2">
                              <div className="col-lg-12">
                                  <div className="card shadow">     
                                  <div className="card-header">Metas</div>                                                                     
                                    <div className="card-body">                                    
                                      <div className="row">
                                        {
                                            Dashboard.listaMetas.map(meta => {
                                                return <div className="col-lg-4">
                                                        <div className="card m-1">
                                                            <div className="card-body">
                                                                <h6 className="text-muted">{meta.tipoMeta}</h6>
                                                                <h6>Periodo: {meta.periodoMeta}</h6>
                                                                <h6>Valor da meta: { meta.tipoMeta === "Valores de Jobs"
                                                                                    ? 'R$ '+ meta.valorMeta
                                                                                    :meta.valorMeta}</h6>
                                                                <h6>Conquistado: {meta.tipoMeta === "Valores de Jobs"
                                                                                ? 'R$ '+ meta.valorAlcancado
                                                                                : meta.valorAlcancado}</h6>
                                                                <h6 style={meta.porcentagemAlcancada >= 100 
                                                                        ? {"color":"green"} 
                                                                        : {"color":"red"}}
                                                                >Porcentagem: {meta.porcentagemAlcancada}%</h6>                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                     </div> 
                                    </div>
                                  </div>
                                  {/* <p style={{marginLeft: '50%'}}><b>-METAS-</b></p> */}
                              </div>                              
                          </div>
                                                  
                      </div>
                    
                    
                    : null
                  }
                                                     
              </div>


          {/* Area do modal */}
          { construirModais()}        
          </div>
          

        </>
    )
}

export default DashboardFC;